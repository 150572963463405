import React from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/css/bootstrap.css";
import "./assets/css/theme.css";
import "./assets/css/maicons.css";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";
import News from "./components/news";
import Services from "./components/services";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
};

export default App;
