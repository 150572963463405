import React from "react";
import ContactImg from "../assets/img/bg_image_4.png";

export default function ContactSection() {
  return (
    <main>
      <div className="page-section">
        <div className="container">
          <div className="row align-items-center">
            {/* Contact Info Section */}
            <div className="col-lg-6 py-3 slide-in-left">
              <h2 className="title-section">
                Connect with Synergy Technologies Solutions!
              </h2>
              <div className="divider"></div>
              <p>
                <b>We're here to help!</b> Whether you have questions about our
                school information systems, accounting solutions, or HR
                software, our dedicated team is ready to assist you.
              </p>

              <p>
                Here are several ways to get in touch with Synergy Technologies
                Solutions:
              </p>

              <ul className="contact-list">
                <li>
                  <div className="icon">
                    <span className="mai-map"></span>
                  </div>
                  <div className="content">
                    Synergy Technologies Solutions Victory Avenue, Quezon City{" "}
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="mai-mail"></span>
                  </div>
                  <div className="content">info@syntechsolutions.org.</div>
                </li>
                <li>
                  <div className="icon">
                    <span className="mai-phone-portrait"></span>
                  </div>
                  <div className="content">0917 5787809</div>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 py-3 slide-in-right">
              <div className="img-place">
                <img src={ContactImg} alt="" />
              </div>
              <br />
              <p>
                (Please note that visits are by appointment only. Contact us
                beforehand to schedule a meeting).
              </p>
              <p>
                We look forward to hearing from you and helping you transform
                your educational institution's operations with our
                cost-effective technology solutions!
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
