import React from "react";
import PageBanner from "./pageBanner";
import BacktoTop from "./back.to.Top.btn";
import Navbar from "./navbar";
import useScrollRevealEffect from "../assets/vendor/animate/scrollreveal.animaton";

export default function About() {
  useScrollRevealEffect();

  const breadcrumbItems = [
    { label: "Home", path: "/home", active: false },
    { label: "News", path: "", active: true },
  ];

  return (
    <>
      <BacktoTop />
      <Navbar />
      <div>
        <PageBanner title="News" breadcrumbItems={breadcrumbItems} />
      </div>

      <main>
        <div className="page-section fade-in-up">
          <div className="container">
            <div className="text-center">
              {/* <div className="subhead">Why Choose Us</div> */}
              <h2 className="title-section">
                {/* Your <span className="marked">Comfort</span> is Our Priority */}
                A Day in my life:
              </h2>
              <div className="divider mx-auto"></div>
              <p style={{ textAlign: "left" }}>
                Hey everyone! So today, I am writing a blog post about one of
                the most recent smooth encounters I had with my school’s
                website, the LWSIS. For those who have no idea, LWSIS platform
                is the single place to go for anything we need that is related
                to this school, be it registration, announcement, class
                timetable among others. Trust me on this one; it is going to
                revolutionize things!
              </p>
              <p style={{ textAlign: "left" }}>
                Morning Mission: Registration Day 📋 This particular day created
                a little preoccupation—I got to know that today was the last day
                to enroll to classes. Yikes! I took my laptop and went to the
                homepage of the LWSIS, thinking I would need an eternity to
                accomplish everything. But, surprise! The moment I arrived at
                the home page, I am sure this was going to be a very easy
                process.
              </p>
              <p style={{ textAlign: "left" }}>
                User-Friendly Dashboard 🖥 On the next page the user is
                introduced to the dashboard after the login procedure has been
                completed. Everything I needed was neatly organized: Class
                Registration, Student Records, Announcements, and everything as
                simple as a Calendar with the key dates of the school year. When
                I pressed on Registration tab, it lead me to other courses
                offered for my program of study.
              </p>
              <p style={{ textAlign: "left" }}>
                Instant and Easy Sign Up 🎓 Here’s the best part: Picking my
                classes, it was possible only check some boxes on the screen.
                Every course had all the specification–timing, professors, and
                even the number of openings offered. After choosing my courses
                the program would alert me to any time clashes that might be
                present (fortunately there were none in my case). When I reached
                my final pick, the site had a large, confidence-inspiring button
                that said Submit Registration. I clicked it and almost
                immediately received a confirmation email. No fuss, no waiting.
                One resource that can be utilized is a treasure trove of
                features. As I was logged in already, I went a step further and
                made some additional searches. Here’s what stood out:
                Announcements: Information on the upcoming school activities,
                examination timetables, other affairs and incidents, and
                opportunities for students’ activities. Fee Payment Portal: I
                was able to pay my tuition from the site saving me a trip to the
                bursar’s office. Student Support had a quick email chat in case
                one had any questions. I tried it with a simple question I am
                having about my account in the library, and they quickly
                answered me in less than a minute.
              </p>
              <p style={{ textAlign: "left" }}>
                Afternoon Accomplishment ✅ By the time I logged out of the
                lwsis portal, I had enrolled for the classes, organized my
                calendar for the semester, made the fees payment and even added
                my timetable to my phone. The last two got done in a few hours
                each leaving a feeling of satisfaction of having several errands
                run through in one sitting. I would like really like to thank
                the developers of the lwsis website; they have really saved my
                life. It is easily navigated, functional, plus or minus all the
                options that a student might require. From registration, to the
                latest announcements, to getting in touch with the support team,
                it is conveniently all here. Just imagine that you are a student
                as I am and you have not discovered all the sides and
                possibilities of the program – oh, you are wrong!
              </p>
              <p style={{ textAlign: "left" }}>
                That’s it for my blog today. Have you used lwsis recently? Share
                your thoughts
              </p>
            </div>
          </div>
        </div>
        {/* <div className="page-section">
          <div className="container">
            <div className="row">
              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person1} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Business Management?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person2} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">What is Assets Management?</a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person3} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Code of Conduct Business?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person1} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Business Management?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person2} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Business Management?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person3} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Business Management?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>
              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person1} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Business Management?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person2} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">What is Assets Management?</a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-md-6 col-lg-4 py-3">
                <div className="card-blog">
                  <div className="header">
                    <div className="avatar">
                      <img src={person3} alt="" />
                    </div>
                    <div className="entry-footer">
                      <div className="post-author">Sam Newman</div>
                      <a href="/" className="post-date">
                        23 Apr 2020
                      </a>
                    </div>
                  </div>
                  <div className="body">
                    <div className="post-title">
                      <a href="blog-single.html">
                        What is Code of Conduct Business?
                      </a>
                    </div>
                    <div className="post-excerpt">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </div>
                  </div>
                  <div className="footer">
                    <a href="blog-single.html">
                      Read More{" "}
                      <span className="mai-chevron-forward text-sm"></span>
                    </a>
                  </div>
                </div>
              </div>

              
              <div className="col-12 mt-5">
                <nav aria-label="Page Navigation">
                  <ul className="pagination justify-content-center">
                    <li className="page-item disabled">
                      <a
                        className="page-link"
                        href="/"
                        tabIndex="-1"
                        aria-disabled="true"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        1
                      </a>
                    </li>
                    <li className="page-item active" aria-current="page">
                      <a className="page-link" href="/">
                        2 <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div> */}
      </main>

      {/* <Footer /> */}
    </>
  );
}
