import { useEffect } from "react";
import ScrollReveal from "scrollreveal";
const useScrollRevealEffect = () => {
  useEffect(() => {
    ScrollReveal({
      reset: true,
      distance: "60px",
      duration: 1000,
      delay: 0,
    });
    ScrollReveal().reveal(".fade-in-up", { origin: "bottom" });
    ScrollReveal().reveal(".scale-in-center", { scale: 0.85 });
    ScrollReveal().reveal(".slide-in-right", { origin: "right" });
    ScrollReveal().reveal(".slide-in-left", { origin: "left" });
  }, []);
};

export default useScrollRevealEffect;
