export default function Features() {
  const features = [
    {
      iconClass: "mai-shapes",
      title: "Affordable Solutions",
      description: "Cost-effective solutions without compromising quality.",
    },
    {
      iconClass: "mai-shapes",
      title: "Expert Team",
      description: "Experienced professionals dedicated to your success.",
    },
    {
      iconClass: "mai-shapes",
      title: "Customer-Centric Approach",
      description: "We prioritize your needs and provide excellent support.",
    },
  ];

  return (
    <div className="page-section">
      <div className="container">
        <div className="text-center fade-in-up">
          {/* <div className="subhead">Why Choose Us</div> */}
          <h2 className="title-section">
            {/* Your <span className="marked">Comfort</span> is Our Priority */}
            Why Choose Us
          </h2>
          <div className="divider mx-auto"></div>
          <div className="row mt-5 text-center">
            {features.map((feature, index) => (
              <div key={index} className="col-lg-4 py-3 fade-in-up">
                <div className="display-3">
                  <span className={feature.iconClass}></span>
                </div>
                <h5>{feature.title}</h5>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
