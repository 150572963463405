import React from "react";
import PageBanner from "./pageBanner";
import BacktoTop from "./back.to.Top.btn";
import Img5 from "../assets/img/bg_image_5.png";
import Img6 from "../assets/img/bg_image_6.png";
import Img7 from "../assets/img/bg_image_7.png";
import Features from "./features";
import Navbar from "./navbar";
import useScrollRevealEffect from "../assets/vendor/animate/scrollreveal.animaton";

export default function About() {
  useScrollRevealEffect();
  const breadcrumbItems = [
    { label: "Home", path: "/home", active: false },
    { label: "About", path: "", active: true },
  ];

  return (
    <div>
      <BacktoTop />
      <Navbar />
      <div>
        <PageBanner
          title="About Synergy Technologies Solutions"
          breadcrumbItems={breadcrumbItems}
        />
      </div>

      <main>
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 py-3 slide-in-left">
                <h2 className="title-section">
                  Your Partner in Educational Technology
                </h2>
                <div className="divider"></div>
                <p>
                  We're passionate about leveraging technology to solve
                  real-world problems. Our team of skilled professionals is
                  dedicated to providing affordable and cost-effective solutions
                  for educational institutions.
                </p>
                <p>
                  We specialize in developing innovative school information
                  systems and accounting and HR solutions that streamline
                  processes, boost efficiency, and enhance decision-making. Our
                  commitment to quality, customer satisfaction, and continuous
                  improvement sets us apart.
                </p>
              </div>
              <div className="col-lg-6 py-3 pr-lg-5 slide-in-right">
                <div className="img-fluid text-center">
                  <img src={Img5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* half */}
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-lg-6 py-3 slide-in-left">
                <div className="img-fluid text-center">
                  <img src={Img6} alt="" />
                </div>
              </div>
              <div className="col-lg-6 py-3 pr-lg-5 slide-in-right">
                <h2 className="title-section">Our Mission</h2>
                <div className="divider"></div>
                <p>
                  To provide reliable and cost-effective technology solutions
                  that empower educational institutions to achieve their goals.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="page-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 py-3 slide-in-left">
                <h2 className="title-section">Our Services</h2>
                <div className="divider"></div>
                <p>
                  <b>School Information Systems:</b> Comprehensive systems to
                  manage student records, attendance, fees, and more.
                  <br />
                  <b>Accounting and HR Solutions:</b> Efficient software to
                  streamline financial and HR processes.
                  <br />
                  <b>Custom Software Development:</b> Tailored solutions to meet
                  specific needs.
                  <br />
                  <b>IT Support and Consulting:</b> Expert support to keep your
                  systems running smoothly.
                </p>
              </div>
              <div className="col-lg-6 py-3 pr-lg-5 slide-in-right">
                <div className="img-fluid text-center">
                  <img src={Img7} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Features />

        <div className="page-section fade-in-up">
          <div className="container">
            <div className="text-center">
              {/* <div className="subhead">Why Choose Us</div> */}
              <h2 className="title-section">
                {/* Your <span className="marked">Comfort</span> is Our Priority */}
                Contact Us
              </h2>
              <div className="divider mx-auto"></div>
              <ul className="contact-list " style={{ textAlign: "left" }}>
                <li>
                  <div className="icon">
                    <span className="mai-map"></span>
                  </div>
                  <div className="content">
                    Synergy Technologies Solutions Victory Ave., Quezon City{" "}
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="mai-mail"></span>
                  </div>
                  <div className="content">info@syntechsolutions.org.</div>
                </li>
                <li>
                  <div className="icon">
                    <span className="mai-phone-portrait"></span>
                  </div>
                  <div className="content">0917 5787809</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      {/* 
      <Footer /> */}
    </div>
  );
}
