import React from "react";
import PageBanner from "./pageBanner";
import "../assets/css/maicons.css";
import BacktoTop from "./back.to.Top.btn";
import Navbar from "./navbar";
import Img4 from "../assets/img/bg_image_4.png";
import useScrollRevealEffect from "../assets/vendor/animate/scrollreveal.animaton";

export default function About() {
  useScrollRevealEffect();

  const breadcrumbItems = [
    { label: "Home", path: "/home", active: false },
    { label: "Contact", path: "", active: true },
  ];

  return (
    <>
      <BacktoTop />
      <Navbar />
      <div>
        <PageBanner title="Contact Us" breadcrumbItems={breadcrumbItems} />
      </div>
      <main>
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center slide-in-left">
              <div className="col-lg-6 py-3">
                <h2 className="title-section">
                  Have a question or need assistance?
                </h2>
                <div className="divider"></div>
                <p>
                  We're here to help! Feel free to reach out to us using the
                  following information:
                </p>

                <ul className="contact-list">
                  <li>
                    <div className="icon">
                      <span className="mai-map"></span>
                    </div>
                    <div className="content">
                      Synergy Technologies Solutions Victory Avenue, Quezon City{" "}
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="mai-mail"></span>
                    </div>
                    <div className="content">info@syntechsolutions.org.</div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="mai-phone-portrait"></span>
                    </div>
                    <div className="content">0917 5787809</div>
                  </li>
                </ul>
                <br />

                <p>We look forward to hearing from you!</p>
              </div>

              <div className="col-lg-6 py-3 pr-lg-5 slide-in-right">
                <div className="img-fluid text-center">
                  <img src={Img4} alt="" style={{ height: "300px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
