import React from "react";
import PageBanner from "./pageBanner";
import BacktoTop from "./back.to.Top.btn";
import Navbar from "./navbar";
import Features from "./features";
import Img7 from "../assets/img/bg_image_7.png";
import useScrollRevealEffect from "../assets/vendor/animate/scrollreveal.animaton";

export default function About() {
  useScrollRevealEffect();
  const breadcrumbItems = [
    { label: "Home", path: "/home", active: false },
    { label: "Services", path: "", active: true },
  ];

  return (
    <>
      <BacktoTop />
      <Navbar />
      <div>
        <PageBanner title="Our Services" breadcrumbItems={breadcrumbItems} />
      </div>
      <main>
        <div className="page-section">
          <div className="container">
            <div className="row align-items-center slide-in-left">
              <div className="col-lg-6 py-3">
                <h2 className="title-section">Our Services</h2>
                <div className="divider"></div>
                <p>
                  <b>School Information Systems:</b> Comprehensive systems to
                  manage student records, attendance, fees, and more.
                  <br />
                  <b>Accounting and HR Solutions:</b> Efficient software to
                  streamline financial and HR processes.
                  <br />
                  <b>Custom Software Development:</b> Tailored solutions to meet
                  specific needs.
                  <br />
                  <b>IT Support and Consulting:</b> Expert support to keep your
                  systems running smoothly.
                </p>
              </div>
              <div className="col-lg-6 py-3 pr-lg-5 slide-in-right">
                <div className="img-fluid text-center">
                  <img src={Img7} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Features />

        <div
          className="page-section"
          style={{ paddingTop: "10", paddingBottom: "50px" }}
        >
          <div className="container">
            <div className="text-center">
              <p className="fade-in-up">
                <b>Ready to take your institution to the next level?</b> Contact
                us today to learn more about how our solutions can benefit you.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
